import React, { FunctionComponent } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Particles from "react-tsparticles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamation,
  faGamepad,
  faHeartbeat,
  faPlay,
  faShieldAlt,
  faSmileBeam,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import OnScreen from "../components/OnScreen"
import TypedText from "../components/TypedText"
import Button from "../components/Button"

const App: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "index-app-bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        allContentfulApp {
          edges {
            node {
              headline
              mission {
                raw
              }
              firstSection {
                raw
              }
              secondSection {
                raw
              }
              thirdSection {
                raw
              }
              fourthSection {
                raw
              }
              fifthSection {
                raw
              }
              benefitsHeading
              firstBenefit {
                raw
              }
              secondBenefit {
                raw
              }
              thirdBenefit {
                raw
              }
              fourthBenefit {
                raw
              }
              fifthBenefit {
                raw
              }
              sixthBenefit {
                raw
              }
              seventhBenefit {
                raw
              }
              eightBenefit {
                raw
              }
              ninthBenefit {
                raw
              }
              tenthBenefit {
                raw
              }
              pledge {
                raw
              }
            }
          }
        }
      }
    `
  )
  const newData = data.allContentfulApp.edges[0].node

  return (
    <Layout absoluteHeader darkFooter>
      <SEO
        description="Unleash the power of momentum with our gamified Weekly Habit Tracker app! A fun, simple, intuitive accountability system to ensure continuous growth every day."
        title="Weekly Habit Tracker App"
        link="https://mooremomentum.com/weekly-habit-tracker-app/"
      />

      <div className="app-hero-bg fixed inset-0" style={{ zIndex: -1 }}>
        <Particles
          canvasClassName="app-particles"
          options={{
            fpsLimit: 60,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "bubble",
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 200,
                  duration: 2,
                  opacity: 0.8,
                  size: 12,
                },
                push: {
                  quantity: 4,
                },
              },
            },
            particles: {
              color: {
                value: "rgba(255, 255, 255, 0.20)",
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                random: true,
                value: 5,
              },
            },
            detectRetina: true,
          }}
        />
      </div>

      <div className="text-white">
        <div className="relative overflow-hidden">
          <div className="mb-16 px-4 pt-32 relative text-center transform z-10 md:pt-48">
            <div className="scale-in-ver-center">
              <h1 className="font-Orbitron max-w-2xl mb-8 mx-auto text-shadow text-3xl md:text-5xl md:leading-tight">
                {newData.headline}{" "}
              </h1>
            </div>

            <StaticImage
              alt="Mooremomentum App Screenshots"
              className="max-w-xs md:max-w-lg lg:max-w-2xl xl:max-w-full"
              src="../images/app-hero-screenshots.png"
            />
          </div>

          <div className="absolute top-56 left-4 md:left-8 md:top-1/2 2xl:left-32 slide-in-left">
            <StaticImage
              alt="Planet"
              className="w-24 sm:w-32 md:w-40 xl:w-44 2xl:w-56"
              src="../images/planet-04.png"
            />
          </div>

          <div className="absolute right-4 top-44 md:right-8 lg:right-24 xl:right-44 2xl:right-64 2xl:top-64">
            <div className="slide-in-right">
              <StaticImage
                alt="Planet"
                className="w-24 sm:w-32 md:w-40 xl:w-44 2xl:w-56"
                src="../images/planet-09.png"
              />
            </div>
          </div>

          <div className="absolute right-8 bottom-0 md:right-16 md:top-3/4 2xl:right-32 2xl:top-2/3">
            <div className="slide-in-right">
              <StaticImage
                alt="Planet"
                className="w-24 sm:w-32 md:w-40 xl:w-44 2xl:w-56"
                src="../images/planet-13.png"
              />
            </div>
          </div>
        </div>

        <div className="relative sm:mb-24">
          <div className="max-w-7xl mb-16 mx-auto px-4 md:mb-0 2xl:mb-64">
            <div className="mb-16 space-y-4 text-left text-link">
              {renderRichText(newData.firstSection, {
                renderMark: {
                  [MARKS.BOLD]: text => (
                    <span className="font-bold">{text}</span>
                  ),
                  [MARKS.ITALIC]: text => <em>{text}</em>,
                },
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                  [BLOCKS.HEADING_1]: (node, children) => (
                    <h1 className="font-Orbitron text-4xl">{children}</h1>
                  ),
                  [BLOCKS.HEADING_2]: (node, children) => (
                    <h2 className="font-Orbitron text-3xl">{children}</h2>
                  ),
                  [BLOCKS.HEADING_3]: (node, children) => (
                    <h3 className="font-Orbitron text-xl">{children}</h3>
                  ),
                  [BLOCKS.EMBEDDED_ASSET]: node => {
                    if (!node.data.target) {
                      return
                    }
                    // console.log("Imagess",node.data.target.gatsbyImageData)
                    const { gatsbyImageData } = node.data.target
                    if (!gatsbyImageData) {
                      // asset is not an image
                      return null
                    }
                    return (
                      <figure className="flex flex-wrap justify-center items-center">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </figure>
                    )
                  },
                },
              })}
            </div>

            <div className="gap-8 grid grid-cols-1 items-center sm:gap-4 sm:grid-cols-2">
              <div className="order-2 sm:order-1 text-link">
                {renderRichText(newData.secondSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>

              <div className="order-1 p-4 text-center sm:order-2">
                <OnScreen classToAdd="scale-in-center">
                  <StaticImage
                    alt="Game Screen Storage"
                    className="w-2/3 mock-shadow sm:max-w-xs sm:w-full"
                    src="../images/app-storage.png"
                  />
                </OnScreen>
              </div>
            </div>

            <hr className="bg-white border-none h-0.5 my-12 sm:bg-transparent" />

            <div className="gap-8 grid grid-cols-1 sm:gap-4 sm:grid-cols-2 2xl:items-center">
              <div className="relative text-center">
                <OnScreen classToAdd="scale-in-center">
                  <StaticImage
                    alt="Game Screen Dashboard"
                    className="w-2/3 mock-shadow sm:max-w-xs sm:w-full"
                    src="../images/app-dashboard.png"
                  />
                </OnScreen>

                <div className="absolute -bottom-4 -right-4 sm:hidden">
                  <div className="overflow-hidden">
                    <OnScreen classToAdd="shake-bottom">
                      <StaticImage
                        alt="Alien With Tentacles"
                        className="w-44"
                        src="../images/app-char-1.png"
                      />
                    </OnScreen>
                  </div>
                </div>
              </div>

              <div className="text-link">
                {renderRichText(newData.thirdSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>
          </div>

          <div className="absolute -bottom-4 hidden right-0 sm:block xl:-bottom-12">
            <div className="overflow-hidden">
              <OnScreen classToAdd="shake-bottom">
                <StaticImage
                  alt="Planet"
                  className="w-44 md:w-56 lg:w-64 xl:w-72"
                  src="../images/app-char-1.png"
                />
              </OnScreen>
            </div>
          </div>
        </div>

        <div className="relative mt-32 sm:mt-0">
          <div className="max-w-7xl mx-auto px-4 pt-4 md:pt-0">
            <div className="gap-8 grid grid-cols-1 items-center sm:gap-4 sm:grid-cols-2">
              <div className="order-2 sm:order-1 text-link">
                {renderRichText(newData.fourthSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>

              <div className="order-1 p-4 text-center sm:order-2">
                <OnScreen classToAdd="scale-in-center">
                  <StaticImage
                    alt="Game Screen Storage"
                    className="w-2/3 mock-shadow sm:max-w-xs sm:w-full"
                    src="../images/app-storage.png"
                  />
                </OnScreen>
              </div>
            </div>
          </div>

          <div className="absolute -top-60 sm:-top-58 lg:-top-58">
            <div className="overflow-hidden">
              <OnScreen classToAdd="slide-in-left">
                <StaticImage
                  alt="Planet"
                  className="max-w-xs sm:max-w-md lg:max-w-full"
                  src="../images/app-planet.png"
                />
              </OnScreen>
            </div>
          </div>
        </div>

        <div className="px-4">
          <hr className="bg-white border-none h-0.5 my-12 sm:bg-transparent" />
        </div>

        <div className="relative">
          <div className="max-w-7xl mx-auto px-4">
            <div className="gap-8 grid grid-cols-1 items-center sm:gap-4 sm:grid-cols-2 md:mb-16">
              <div className="relative text-center xl:text-center lg:static">
                <OnScreen classToAdd="scale-in-center">
                  <StaticImage
                    alt="Game Screen Profile"
                    className="w-2/3 mock-shadow sm:max-w-xs sm:w-full"
                    src="../images/app-profile.png"
                  />
                </OnScreen>

                <div className="absolute -bottom-4 -left-4 lg:-bottom-16 2xl:bottom-0">
                  <div className="overflow-hidden">
                    <OnScreen classToAdd="shake-bottom">
                      <StaticImage
                        alt="Red Alien"
                        className="w-32 md:w-36 lg:w-48 xl:w-56"
                        src="../images/app-char-2.png"
                      />
                    </OnScreen>
                  </div>
                </div>
              </div>

              <div className="text-link">
                {renderRichText(newData.fifthSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden">
          <OnScreen classToAdd="slide-in-right">
            <div className="container mx-auto py-16 px-4">
              <div className="relative rounded-md shadow-lg">
                <BackgroundImage fluid={data.file.childImageSharp.fluid}>
                  <div>
                    <div className="absolute bg-black bg-opacity-30 inset-0 rounded-md" />

                    <div className="absolute bottom-0 hidden left-0 z-0 md:block md:w-36 xl:w-full">
                      <StaticImage
                        alt="Green Alien"
                        className="w-32"
                        placeholder="blurred"
                        src="../images/alien.png"
                      />
                    </div>

                    <div className="gap-16 grid grid-cols-1 pt-8 px-8 text-white z-10 md:gap-4 md:grid-cols-2 md:pt-0 md:px-0">
                      <div className="max-w-md mx-auto space-y-4 z-10 md:max-w-lg md:pb-8 md:pl-8 md:pt-8 2xl:self-center">
                        <TypedText
                          className="font-Press-Start mb-4 text-coral text-lg"
                          text="COMING SOON..."
                        />

                        {renderRichText(newData.mission, {
                          renderMark: {
                            [MARKS.BOLD]: text => (
                              <span className="font-bold">{text}</span>
                            ),
                            [MARKS.ITALIC]: text => <em>{text}</em>,
                          },
                          renderNode: {
                            [BLOCKS.PARAGRAPH]: (node, children) => (
                              <p>{children}</p>
                            ),
                            [BLOCKS.HEADING_1]: (node, children) => (
                              <h1 className="font-Orbitron text-4xl">
                                {children}
                              </h1>
                            ),
                            [BLOCKS.HEADING_2]: (node, children) => (
                              <h2 className="font-Orbitron text-3xl">
                                {children}
                              </h2>
                            ),
                            [BLOCKS.HEADING_3]: (node, children) => (
                              <h3 className="font-Orbitron text-xl">
                                {children}
                              </h3>
                            ),
                            [BLOCKS.EMBEDDED_ASSET]: node => {
                              if (!node.data.target) {
                                return
                              }
                              // console.log("Imagess",node.data.target.gatsbyImageData)
                              const { gatsbyImageData } = node.data.target
                              if (!gatsbyImageData) {
                                // asset is not an image
                                return null
                              }
                              return (
                                <figure className="flex flex-wrap justify-center items-center">
                                  <GatsbyImage
                                    image={gatsbyImageData}
                                    alt="Blog Image"
                                  />
                                </figure>
                              )
                            },
                          },
                        })}

                        <form
                          action="https://mooremomentum.us20.list-manage.com/subscribe/post"
                          method="POST"
                        >
                          <input
                            type="hidden"
                            name="u"
                            value="705d046da14d7bc6e462e6ec4"
                          />
                          <input type="hidden" name="id" value="fc1be8fe04" />
                          {/* Hidden tag for app */}
                          <input type="hidden" name="tags" value="2083437" />
                          {/* Hidden tag for app */}

                          <div className="mb-8 space-y-4">
                            <div className="gap-4 grid sm:grid-cols-2">
                              <div>
                                <input
                                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                                  id="MERGE1"
                                  name="MERGE1"
                                  placeholder="First Name"
                                  required
                                  type="text"
                                />
                              </div>

                              <div>
                                <input
                                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                                  id="MERGE2"
                                  name="MERGE2"
                                  placeholder="Last Name"
                                  required
                                  type="text"
                                />
                              </div>
                            </div>

                            <div>
                              <input
                                className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                                id="MERGE0"
                                name="MERGE0"
                                placeholder="Email Address"
                                required
                                type="email"
                              />
                            </div>
                          </div>

                          <div className="text-center">
                            <Button className="px-12" type="submit">
                              Notify
                            </Button>
                          </div>
                        </form>
                      </div>

                      <div className="flex items-end justify-center sm:max-w-xs sm:mx-auto">
                        <StaticImage
                          alt="Game Home Screen"
                          placeholder="blurred"
                          src="../images/app-mock-index.png"
                        />
                      </div>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            </div>
          </OnScreen>
        </div>

        <div className="px-4">
          <OnScreen
            className="bg-blue-dark bg-opacity-80 max-w-4xl mb-16 mx-auto p-8 rounded-md space-y-4"
            classToAdd="slide-in-left"
          >
            <h2 className="font-Orbitron mb-8 text-center text-2xl lg:text-3xl">
              {newData.benefitsHeading}
            </h2>

            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faGamepad} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.firstBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>

            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faShieldAlt} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.secondBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>

            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faExclamation} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.thirdBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>

            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faHeartbeat} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.fourthBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>

            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faPlay} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.fifthBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>

            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faThumbsUp} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.sixthBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>

            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faSmileBeam} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.seventhBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faSmileBeam} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.eightBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faSmileBeam} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.ninthBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-center w-8">
                <FontAwesomeIcon className="text-2xl" icon={faSmileBeam} />
              </div>

              <div className="flex-1 ml-4 text-link">
                {renderRichText(newData.tenthBenefit, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </div>
          </OnScreen>
        </div>
        <div className="relative sm:mb-24">
          <div className="max-w-7xl mb-16 mx-auto px-4 md:mb-0 2xl:mb-64">
            <div className="mb-16 space-y-4 text-left text-link">
              {renderRichText(newData.pledge, {
                renderMark: {
                  [MARKS.BOLD]: text => (
                    <span className="font-bold">{text}</span>
                  ),
                  [MARKS.ITALIC]: text => <em>{text}</em>,
                },
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                  [BLOCKS.HEADING_1]: (node, children) => (
                    <h1 className="font-Orbitron text-4xl">{children}</h1>
                  ),
                  [BLOCKS.HEADING_2]: (node, children) => (
                    <h2 className="font-Orbitron text-3xl">{children}</h2>
                  ),
                  [BLOCKS.HEADING_3]: (node, children) => (
                    <h3 className="font-Orbitron text-xl">{children}</h3>
                  ),
                  [BLOCKS.EMBEDDED_ASSET]: node => {
                    if (!node.data.target) {
                      return
                    }
                    // console.log("Imagess",node.data.target.gatsbyImageData)
                    const { gatsbyImageData } = node.data.target
                    if (!gatsbyImageData) {
                      // asset is not an image
                      return null
                    }
                    return (
                      <figure className="flex flex-wrap justify-center items-center">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </figure>
                    )
                  },
                },
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default App
